//COLORS
$primary-color: #4A148C;
$primary-color-500: #5B24C3;
$primary-color-200: #C1A0FF;
$primary-color-50: #ECE2FF;
$on-hold-color: #FAE094;
$waiting-color: #CEF3FF;
$active-color: #D5EE92;
$disabled-color: #FFDDDD;
$archived-color: #EEEEEE;


$accent-color: #0ED1C1;

$gray-color-50: #FAFAFA;
$gray-color-100: #F5F5F5;
$gray-color-200: #EEEEEE;
$gray-color-300: #E0E0E0;
$gray-color-400: #BDBDBD;
$gray-color-500: #9E9E9E;
$gray-color-600: #757575;
$gray-color-700: #616161;
$gray-color-800: #424242;
$gray-color-900: #212121;
$gray-color-700-opacity: #6161612C;


$wrong-color: #E04141;
$red: #F44336;
$right-color: #00C566;

  //FONT
$font-regular-size: 14px;
$font-small-size: 12px;
$font-large-size: 16px;
$font-heading-1-size: 36px;
$font-heading-2-size: 28px;
$font-heading-3-size: 24px;
$font-heading-4-size: 20px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

//OTHER
$border-radius: 8px;
$regular-padding: 16px;
$regular-padding_2: 16px;
$regular-padding_3: 24px;









