@use "palette";
@use '@angular/material' as mat;
@use "@material/icon-button";

@import "styles/ux-variables";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@include mat.core();

$my-primary: mat.define-palette(palette.$hunch-primary-palette);
$my-accent: mat.define-palette(palette.$hunch-accent-palette);
$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  )
));
@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);
@include mat.all-component-themes($my-theme);
@import "styles/material";


html, body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  font-weight: $font-weight-regular;
  font-size: $font-regular-size;
  a {
    color: unset;
    text-decoration: none;
  }
  h1 {
    font-size: $font-heading-1-size!important;
    font-weight: $font-weight-bold!important;
  }
  h2 {
    font-size: $font-heading-2-size!important;
    font-weight: $font-weight-medium!important;
    margin: 0!important;
  }
  h3 {
    font-size: $font-heading-3-size!important;
    font-weight: $font-weight-bold!important;
  }
  h4 {
    font-size: $font-heading-4-size!important;
    font-weight: $font-weight-medium!important;
  }

  .body-big-bold {
    font-size: $font-large-size;
    font-weight: $font-weight-bold;
  }

  .body-big-medium {
    font-size: $font-large-size;
    font-weight: $font-weight-medium;
  }

  .body-big-regular {
    font-size: $font-large-size;
    font-weight: $font-weight-regular;
  }

  .body-medium-bold {
    font-size: $font-regular-size;
    font-weight: $font-weight-bold;
  }

  .body-medium-medium {
    font-size: $font-regular-size;
    font-weight: $font-weight-medium;
  }

  .body-medium-regular {
    font-size: $font-regular-size;
    font-weight: $font-weight-regular;
  }

  .body-small-bold {
    font-size: $font-small-size;
    font-weight: $font-weight-bold;
  }

  .body-small-medium {
    font-size: $font-small-size;
    font-weight: $font-weight-medium;
  }

  .body-small-regular {
    font-size: $font-small-size;
    font-weight: $font-weight-regular;
  }
  .disabled-overlay {
    background: rgba(55, 62, 72, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.game-builder {
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        overflow: visible;
      }
    }
  }
}


.cdk-overlay-container {
  &.mystyle {
    //.cdk-overlay-backdrop {
    //  background: none;
    //}
    .cdk-global-overlay-wrapper {
      z-index: 999;
    }
  }
  &.event {
    .cdk-overlay-connected-position-bounding-box {
      .cdk-overlay-pane {
        &.mat-mdc-autocomplete-panel-above {
          .mat-mdc-autocomplete-panel {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top: none;
            box-shadow: 4px -8px 9px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);;
            //border-bottom-color: $primary-color;
            //border-bottom-style: solid;
            //border-bottom-width: 2px;
          }
        }
        .mat-mdc-autocomplete-panel {
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
          //border-top-style: solid;
          //border-top-width: 2px;
          //border-top-color: $primary-color;
        }
      }
    }
  }
}
